import { get } from 'lodash-es';

import { Company } from 'src/global/models';
import { HierarchicalLocation } from 'src/modules/Profile/graphql/hierarchicalLocations';

export const useCompanyLocation = (company?: Company): string | null => {
  if (!company) return null;

  const location = upsertWithFormattedLocationName(company);
  if (location) {
    return [...(location.parents ?? []), location]
      .sort((a, b) => b.level - a.level)
      .map((obj) => obj.formattedName)
      .join(', ');
  }
  const cityName = get(company, 'links.city.name');
  const countryName = get(company, 'links.country.name');
  return cityName ? `${cityName}, ${countryName}` : countryName;
};

// In REST endpoint, formattedName is not returned inside HierarchicalLocation, but in another property called locationFormattedNames inside Company model. We need to insert this formattedName into the HierarchicalLocation manually.
const upsertWithFormattedLocationName = (
  company: Company
): HierarchicalLocation | null => {
  const locationFormattedNames = company.locationFormattedNames;
  const hierarchicalLocation = company.hierarchicalLocation;
  if (locationFormattedNames && hierarchicalLocation) {
    try {
      const parents = hierarchicalLocation.parents?.map((parent) => ({
        ...parent,
        formattedName:
          locationFormattedNames.find((item) => item.locationId === parent.id)
            ?.formattedName ?? '',
      }));

      const updatedLocation = {
        ...hierarchicalLocation,
        formattedName:
          locationFormattedNames.find(
            (item) => item.locationId === hierarchicalLocation.id
          )?.formattedName ?? '',
        parents,
      };
      return updatedLocation;
    } catch (_) {
      console.error('upsertWithFormattedLocationName: Failed to upsert');
      return hierarchicalLocation ?? null;
    }
  }
  return hierarchicalLocation ?? null;
};
