import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SkeletonImageSquare } from 'glints-aries/lib/@next';
import { PrimaryButton } from 'glints-aries/lib/@next/Button';
import { Popover } from 'glints-aries/lib/@next/Popover';
import { Typography } from 'glints-aries/lib/@next/Typography';
import DOMPurify from 'isomorphic-dompurify';
import { isUndefined } from 'lodash';
import moment from 'moment';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';

import { CompanyStatus } from 'src/common/enums/companies';
import { useCompanyLocation } from 'src/common/hooks/useCompanyLocation';
import { useCurrentHref } from 'src/common/hooks/useUrl';
import { useGlintsAssetURL } from 'src/components/GlintsPicture/hooks';
import { AssetType } from 'src/components/GlintsPicture/interfaces';
import { MetadataRenderer } from 'src/components/MetaDescription/MetadataRenderer';
import {
  Company,
  companySizeMap,
  VerificationTierType,
} from 'src/global/models/Company';
import { CompanySchemaType } from 'src/modules/Company/types/companySchemaType';

import * as S from '../../CompanyPage.sc';
import { TierVerificationBadge } from '../TierVerificationBadge';
import { CompanyTabTypes, getLocalizationTab, TabListItems } from './const';
import { getVerifiedArticalUrl } from './helper';
import * as Styled from './styles.sc';

const CompanyTagLine = dynamic(
  import(
    'src/modules/Company/components/TopFoldCompanySection/CompanyTagLine'
  ).then((mod) => mod.CompanyTagLine),
  {
    ssr: true,
    loading: () => <SkeletonImageSquare width="100%" height={'180px'} />,
  }
);

const metadataTitleMessage = defineMessage({
  id: 'company-page.metadata.title',
  defaultMessage: '{companyName} Career Information {year} | Glints',
});

const metadataDescriptionMessage = defineMessage({
  id: 'company-page.metadata.description',
  defaultMessage:
    'Apply to job opportunities at {companyName}. Get the latest information about building career at {companyName}, review & company culture with Glints',
});
const getMonth = () => moment().format('MMMM');
const getMonthNumber = () => moment().format('M');

interface Props {
  company?: Company;
  relevantJobsLength: number | undefined;
  sectionRefs: React.RefObject<HTMLElement>[] | null[];
}

export const TopFoldCompanySection: React.FC<
  React.PropsWithChildren<Props>
> = ({ company, sectionRefs, relevantJobsLength }) => {
  const intl = useIntl();
  const lang = intl.locale;
  const url = useCurrentHref();
  const companyLocation = useCompanyLocation(company);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  const [verifiedPopoverActive, setVerifiedPopoverActive] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const showPopover = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setVerifiedPopoverActive(true);
  };

  const hidePopover = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setVerifiedPopoverActive(false);
    }, 300);
  }, []);

  const cancelHidePopover = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  const noParamsUrl = url.split('?')[0];
  const month = getMonth();
  const monthNumber = getMonthNumber();

  const isVIP = company?.isVIP ?? false;
  const verificationTierType = company?.verificationTier?.type;
  const isLegalDocumentVerified =
    verificationTierType === VerificationTierType.LEGAL_DOCUMENTS;
  const isCompanyVerified = company?.status === CompanyStatus.VERIFIED;
  const showBadge = isCompanyVerified && isLegalDocumentVerified;
  const industryName = company?.industry?.name;

  const title = intl.formatMessage(metadataTitleMessage, {
    companyName: company?.name,
    month,
    year: new Date().getFullYear(),
  });

  const description = intl.formatMessage(metadataDescriptionMessage, {
    companyName: company?.name,
    year: new Date().getFullYear(),
    monthNumber,
    month,
  });

  const meta = {
    title,
    description,
    canonical: noParamsUrl,
  };

  const companyLogoURL = useGlintsAssetURL({
    imageSrc: company?.logo ?? '',
    assetType: AssetType.companyLogo,
  });

  const companyDescription = company?.descriptionJsonString
    ? JSON.parse(company.descriptionJsonString)
    : null;

  const companySchema: CompanySchemaType = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    logo: company?.logo ? companyLogoURL : null,
    name: company?.name ? DOMPurify.sanitize(company.name) : null,
    url: company?.website ? DOMPurify.sanitize(company.website) : null,
    description: companyDescription,
    industry: industryName ? DOMPurify.sanitize(industryName) : null,
    address: {
      '@type': 'PostalAddress',
      streetAddress: company?.address,
      addressLocality: company?.hierarchicalLocation?.formattedName,
      addressCountry: company?.hierarchicalLocation?.parents?.find(
        (location) => location.level === 1
      )?.formattedName,
      addressRegion: company?.hierarchicalLocation?.parents?.find(
        (location) => location.level === 2
      )?.formattedName,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: company?.hierarchicalLocation?.latitude,
      longitude: company?.hierarchicalLocation?.longitude,
    },
  };

  useEffect(() => {
    const onScroll = () => setIsScrolling(window.scrollY > 0);

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Styled.Main>
      <Styled.CollapsibleContainer isScrolling={isScrolling}>
        <MetadataRenderer {...meta} schema={companySchema} />
        <Head>
          <If condition={!isCompanyVerified}>
            <meta name="robots" content="noindex" />
          </If>
        </Head>
        <Styled.Header>
          <Styled.LogoDesktop>
            <Styled.Logo
              logo={company?.logo}
              alt="Company Logo"
              sizes="160px"
              breakpoints={160}
              lazy={true}
            />
          </Styled.LogoDesktop>

          <S.Overview>
            <S.CompanyNameContainer>
              <S.NameWrapper>
                <If condition={showBadge}>
                  <TierVerificationBadge
                    tierType={verificationTierType}
                    isVIP={isVIP}
                  />
                </If>
                <Typography variant="headline5" as="h1">
                  {company?.name}
                </Typography>
              </S.NameWrapper>
              <Styled.LogoMobile>
                <Styled.Logo
                  logo={company?.logo}
                  alt="Company Logo"
                  sizes="64px"
                  breakpoints={64}
                  lazy={true}
                />
              </Styled.LogoMobile>
            </S.CompanyNameContainer>

            <CompanyTagLine tagline={company?.tagline} />

            <Styled.AttributeList>
              <If condition={companyLocation}>
                <Styled.AttributeItem>
                  <S.AttributeIcon name="ri-map-line" />
                  <Styled.AttributeValue>
                    {companyLocation}
                  </Styled.AttributeValue>
                </Styled.AttributeItem>
              </If>
              {company?.size && (
                <Styled.AttributeItem>
                  <S.AttributeIcon name="ri-group-line" />
                  <Styled.AttributeValue>
                    {intl.formatMessage(companySizeMap[company.size])}
                  </Styled.AttributeValue>
                </Styled.AttributeItem>
              )}

              <If condition={industryName}>
                <Styled.AttributeItem>
                  <S.AttributeIcon name="ri-building-line" />
                  <Styled.AttributeValue>{industryName}</Styled.AttributeValue>
                </Styled.AttributeItem>
              </If>

              {company?.verifiedAt && (
                <Styled.AttributeItem>
                  <S.AttributeIcon name="ri-shield-check-line" />
                  <Styled.AttributeValue>
                    <FormattedMessage
                      id="text-verified-date"
                      defaultMessage="Verified {dateTime}"
                      values={{
                        dateTime: moment
                          .unix(company.verifiedAt)
                          .format('MMMM YYYY'),
                      }}
                    />
                    <Popover
                      active={verifiedPopoverActive}
                      preferredPosition="above"
                      activator={
                        <S.VerifiedPopoverIcon
                          name="ri-information-line"
                          onMouseEnter={showPopover}
                          onMouseLeave={hidePopover}
                        />
                      }
                      onClose={() => setVerifiedPopoverActive(false)}
                    >
                      <Popover.Pane>
                        <S.PopoverContainer
                          onMouseEnter={cancelHidePopover}
                          onMouseLeave={hidePopover}
                        >
                          <S.PopoverTextContainer>
                            <Typography as="span" variant="subtitle1">
                              <FormattedMessage
                                id="title-text-verified-badge"
                                defaultMessage="Verified Badge"
                              />
                            </Typography>
                            <Typography variant="body1">
                              <FormattedMessage
                                id="body-text-verified-badge"
                                defaultMessage="Glints has verified this company’s legal documents"
                              />
                            </Typography>
                          </S.PopoverTextContainer>
                          <div>
                            <PrimaryButton
                              onClick={() =>
                                window.open(
                                  getVerifiedArticalUrl(lang),
                                  '_blank'
                                )
                              }
                            >
                              <FormattedMessage
                                id="text-learn-more"
                                defaultMessage="Learn More"
                              />
                            </PrimaryButton>
                          </div>
                        </S.PopoverContainer>
                      </Popover.Pane>
                    </Popover>
                  </Styled.AttributeValue>
                </Styled.AttributeItem>
              )}
            </Styled.AttributeList>
            <If condition={isCompanyVerified && isVIP}>
              <S.VipTextSection>
                <S.VipTextSectionBadgeImage src="images/icons/vip-badge.svg" />
                <S.VipTextRightSection>
                  <FormattedMessage
                    id="text-premium-employer"
                    defaultMessage="Premium Employer"
                    tagName={S.VipTextHeadline}
                  />
                  <FormattedMessage
                    id="text-verified-company-enhance-hiring-process"
                    defaultMessage="This verified company uses Premium features to enhance their hiring process"
                    tagName="p"
                  />
                </S.VipTextRightSection>
              </S.VipTextSection>
            </If>
          </S.Overview>
        </Styled.Header>
      </Styled.CollapsibleContainer>

      <Styled.TabsListContainer>
        <Styled.TabsSection>
          <Styled.TabList>
            {!isUndefined(relevantJobsLength) && (
              <Tabs
                sectionRefs={sectionRefs}
                relevantJobsLength={relevantJobsLength}
              />
            )}
          </Styled.TabList>
        </Styled.TabsSection>
      </Styled.TabsListContainer>
    </Styled.Main>
  );
};

const Tabs = ({
  sectionRefs,
  relevantJobsLength,
}: Pick<Props, 'sectionRefs'> & {
  relevantJobsLength: number;
}) => {
  const hasJobs = relevantJobsLength > 0;

  const [currentTab, setCurrentTab] = useState<CompanyTabTypes>(
    hasJobs ? TabListItems[0] : TabListItems[1]
  );

  const scrollToSection = (tab: CompanyTabTypes) => {
    const index = TabListItems.indexOf(tab);
    const sectionRef = sectionRefs[index];
    if (index !== -1 && sectionRef) {
      sectionRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const selectedTabs = hasJobs ? TabListItems : TabListItems.slice(1);

  const handleTabClick = (tab: CompanyTabTypes) => {
    setCurrentTab(tab);
    scrollToSection(tab);
  };

  return (
    <>
      {selectedTabs.map((tab, key) => {
        return (
          <Styled.TabListItem
            key={key}
            className={currentTab === tab ? 'active' : ''}
            onClick={() => handleTabClick(tab)}
          >
            <Typography variant="body1">
              {getLocalizationTab(tab, relevantJobsLength)}
            </Typography>
          </Styled.TabListItem>
        );
      })}
    </>
  );
};
